import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableColumn } from 'src/app/models/utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableComponent implements OnInit {
  public dataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  @Input() tableColumns: TableColumn[];
  @Input() columnTemplates!: Record<string, TemplateRef<any>>;
  @Input() columnClasses!: Record<string, string>;
  @Input() actions!: TemplateRef<any>;

  @Input() set tableData(data: any[]) {
    this.setDataSource(data);
  }

  constructor() {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    this.actions && columnNames.push('actions');
    this.displayedColumns = columnNames;
  }

  setDataSource(data: any) {
    this.dataSource = new MatTableDataSource<typeof data>(data);
  }
}

<div class="table-container">
  <table mat-table [dataSource]="dataSource">
    <ng-container
      *ngFor="let column of tableColumns"
      [matColumnDef]="column.name"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="{ 'text-right': column.align === 'right' }"
      >
        {{ column.label }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="column.title ? column.title(row) : null"
        [class]="columnClasses && columnClasses[column.name]"
        [ngClass]="{ 'text-right': column.align === 'right' }"
      >
        <ng-container
          *ngIf="
            columnTemplates && columnTemplates[column.name];
            else defaultColumn
          "
        >
          <ng.template
            [ngTemplateOutlet]="columnTemplates[column.name]"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng.template>
        </ng-container>
        <ng-template #defaultColumn>
          {{ column.cell(row) }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container *ngIf="actions" matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <ng.template
          [ngTemplateOutlet]="actions"
          [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng.template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
